import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';
import * as format from 'date-fns/format';
import { clientConfig } from './../../../../../environments/client-config';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { environment } from '@env/environment';
import { InputInvoiceOverviewEditComponent } from '../../../input/invoice-overview/edit/edit.component';
import { InputPurchaseOrderManualUploadComponent } from '../../../input/purchase-order/manual-upload/manual-upload.component';
import { InvoiceInvoiceOverviewEditTicketComponent } from '../../../invoice/invoice-overview/edit-ticket/edit-ticket.component';
@Component({
  selector: 'app-invoice-invoice-overview',
  templateUrl: './expense-overview.component.html',
  styleUrls: ['./expense-overview.component.less'],
})
export class InputInvoiceOverviewExpenseOverviewComponent implements OnInit {
  saleTabs: any[] = [{ key: 'vat', id: 1, show: true }, { key: 'ticket', id: 2 }];
  // 增值税管理
  show = false;
  more = 'down';
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '报销编号', index: 'invoiceSeq', render: 'invoiceSeq', width: 100 },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 150 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 150 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 200 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 200 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 50 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 100 },
    // { title: '进项转出', index: 'invoiceTransOut', i18n: 'st.invoiceTransOut', width: 100 },
    { title: '开票日期', index: 'invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 150 },
    { title: '发票类型', index: 'invoiceEntity', render: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    // { title: '入账日期', index: 'entryDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.entryDate', width: 150 },
    { title: '上传日期', index: 'invoiceUploadDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 150 },
    { title: '上传人', index: 'createUserName', i18n: 'st.createUserName', width: 100 },
    { title: '上传方式', index: 'invoiceUploadType', i18n: 'st.invoiceUploadType', render: 'invoiceUploadType', width: 100 },
    // { title: '验真分类', index: 'invoiceVerifyTruth', i18n: 'st.invoiceVerifyTruth', render: 'invoiceVerifyTruth', width: 100 },

    { title: '明细', index: 'itemsDesc', render: 'itemsDesc', width: 120 },
    { title: '异常信息', index: 'invoiceErrorDescription', render: 'invoiceErrorDescription', width: 120 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 120 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 80,
      buttons: [
        {
          text: '发票信息',
          i18n: 'input.invoiceInfo',
          type: 'modal',
          size: 1100,
          component: InputInvoiceOverviewEditComponent,
          paramName: 'i',
          click: (item: any) => this.getList(),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    invoiceCode: '',
    invoiceNumber: '',
    invoiceStatus: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCreateDateArray: '',
    invoiceTotalPriceBegin: '',
    invoiceTotalPriceEnd: '',
    invoiceFromto: '报销单管理',
    invoiceEntity: '',
    createUserName: '',
    invoiceUploadDateArray: '',
    menuType: '1',
  };
  createDate: '';
  uploadDate: '';
  pageTotal: any = 0; // 列表记录总数
  // 旅客运输管理
  ticketShow = false;
  ticketMore = 'down';
  ticketSelectedRows: STData[] = []; // 选中数据
  ticketRows: any = [];
  @ViewChild('ticketST', { static: false }) ticketST: STComponent;
  ticketColumns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '报销编号', index: 'reportNumber', render: 'reportNumber', i18n: 'st.ticket.number', width: 100 },
    { title: '发票类型', index: 'fields', render: 'fields', i18n: 'st.invoiceEntity', width: 100 },
    { title: '票号', index: 'ticketNumber', i18n: 'st.ticket.ticketNumber', width: 100 },
    { title: '上传日期', index: 'createTime', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 100 },
    { title: '开票日期', index: 'issueDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 100 },
    { title: '上传人', index: 'name', i18n: 'st.ticket.createUserName', width: 100 },
    { title: '金额', index: 'amount', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoicePrice', width: 100 },
    { title: '税率', index: 'rate', render: 'rate', i18n: 'input.invoiceInfo.sphSlv', width: 100 },
    { title: '税额', index: 'tax', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 100 },
    { title: '识别状态', index: 'ocrStatus', render: 'ocrStatus', i18n: 'st.ticket.ocrStatus', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 80,
      buttons: [
        {
          text: '发票信息',
          i18n: 'input.invoiceInfo',
          type: 'modal',
          size: 1100,
          component: InvoiceInvoiceOverviewEditTicketComponent,
          paramName: 'i',
          click: (item: any) => this.getTicketList(),
        },
      ]
    }
  ];
  // 列表查询参数
  ticketQueryParams: any = {
    page: 1,
    limit: 10,
    ocrStatus: '',
    reportNumber: '',
    name: '',
    invoiceCreateDateArray: '', // 开票日期
    fields: '',
    invoiceUploadDateArray: '', // 上传日期
    amountBegin: '', // 票面金额最小值
    amountEnd: '', // 票面金额最大值
  };
  ticketCreateDate: '';
  ticketUploadDate: '';
  ticketPageTotal: any = 0; // 列表记录总数
  loading = false;
  invoiceStatusOption = clientConfig.invoiceStatusOption; // 全部发票状态
  VATITInvoiceTypeOption = clientConfig.VATITInvoiceTypeOption; // vatit发票类型
  VATITInvoiceTypeList = this.VATITInvoiceTypeOption.map(item => item.value);
  ocrStatusOption = [
    { value: 0, label: '识别失败' },
    { value: 1, label: '识别成功' },
  ];


  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) { }

  ngOnInit() {
    this.getList();
    this.getTicketList();
  }

  // 获取增值税发票列表
  getList() {
    this.loading = true;
    this.http.get('/input/invoice/billList', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
        // 报销编号处理
        this.rows.map(item => {
          const file = item.invoiceImage.split('/').slice(-1).toString();  // 截取最后一个文件名
          const filename = file.split('.')[0];
          if (filename.indexOf('-') === -1){
            item.reportNumber = filename;
          }else{
            const arr = filename.split('-');
            item.reportNumber = arr[1]
          }
          // = file.split('.')[0];  // 去除文件名后缀.jpg
        })
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 旅客运输-发票列表
  getTicketList() {
    this.loading = true;
    this.http.get('/input/invoice/invoiceUnformatList', this.ticketQueryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.ticketRows = data.page.list;
        this.ticketQueryParams.page = data.page.currPage;
        this.ticketQueryParams.limit = data.page.pageSize;
        this.ticketPageTotal = data.page.totalCount;
        // 报销编号处理
        this.ticketRows.map(item => {
          item.reportNumber = item.reportNumber.split('.')[0];  // 去除文件名后缀.jpg
        })
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 手动上传
  manualUpload() {
    this.modal.static(InputPurchaseOrderManualUploadComponent, { i: { ptype: 0, uploadType: '1', invoiceFromto: '单票查询' } }, 1100).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 旅客运输-票据上传
  ticketUpload() {
    this.modal.static(InputPurchaseOrderManualUploadComponent, { i: { ptype: 2 } }, 1100).subscribe((success) => {
      if (success) {
        this.getTicketList();
      }
    });
  }

  // 刷新列表
  refreshList() {
    this.getList();
    this.getTicketList();
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      invoiceCode: '',
      invoiceNumber: '',
      invoiceStatus: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCreateDateArray: '',
      invoiceTotalPriceBegin: '',
      invoiceTotalPriceEnd: '',
      invoiceFromto: '单票查询',
      invoiceEntity: '',
      createUserName: '',
      invoiceUploadDateArray: '',
      menuType: '1',
    };
    this.createDate = '';
    this.uploadDate = '';
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 旅客运输-展开
  ticketShowOrHidden() {
    this.ticketShow = !this.ticketShow;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.ticketShow) {
      this.ticketMore = 'up';
    } else {
      this.ticketMore = 'down';
    }
  }

  // 导出发票信息
  export() {
    if (this.selectedRows.length > 0) {
      this.exportById();
    } else {
      this.exportBySearch();
    }
  }

  // 按所选数据导出
  exportById() {
    const ids = this.selectedRows.map(item => item.id).join(',');
    const token = this.tokenService.get().token;
    window.open(environment.SERVER_URL + "/input/invoice/exportRecordListByIds?invoiceIds=" +
      ids +
      "&title=发票总览列表" +
      "&Authorization=" + token
    );
    this.selectedRows = [];
    this.st.reload();
  }

  // 按查询条件导出
  exportBySearch() {
    const token = this.tokenService.get().token;
    window.open(environment.SERVER_URL + "/input/invoice/exportRecordList?invoiceCode=" +
      this.queryParams.invoiceCode +
      "&invoiceNumber=" +
      this.queryParams.invoiceNumber +
      "&invoicePurchaserCompany=" +
      this.queryParams.invoicePurchaserCompany +
      "&invoiceSellCompany=" +
      this.queryParams.invoiceSellCompany +
      "&invoiceCreateDateArray=" +
      this.queryParams.invoiceCreateDateArray +
      "&invoiceStatus=" +
      this.queryParams.invoiceStatus +
      "&invoiceEntity=" +
      this.queryParams.invoiceEntity +
      "&invoiceUploadDateArray=" +
      this.queryParams.invoiceUploadDateArray +
      "&invoiceTotalPriceBegin=" +
      this.queryParams.invoiceTotalPriceBegin +
      "&invoiceTotalPriceEnd=" +
      this.queryParams.invoiceTotalPriceEnd +
      "&createUserName=" +
      this.queryParams.createUserName +
      "&invoiceFromto=报销单管理" +
      "&Authorization=" + token
    );
  }

  // 旅客运输列表导出
  ticketExport() {
    const ids = this.ticketSelectedRows.map(item => item.id).join(',') || '';
    const token = this.tokenService.get().token;
    window.open(environment.SERVER_URL + "/input/invoice/exportUnformatList?invoiceIds=" +
      ids +
      "&ocrStatus=" +
      this.ticketQueryParams.ocrStatus +
      "&reportNumber=" +
      this.ticketQueryParams.reportNumber +
      "&name=" +
      this.ticketQueryParams.name +
      "&invoiceCreateDateArray=" +
      this.ticketQueryParams.invoiceCreateDateArray +
      "&fields=" +
      this.ticketQueryParams.fields +
      "&invoiceUploadDateArray=" +
      this.ticketQueryParams.invoiceUploadDateArray +
      "&amountBegin=" +
      this.ticketQueryParams.amountBegin +
      "&amountEnd=" +
      this.ticketQueryParams.amountEnd +
      "&title=旅客运输管理列表" +
      "&Authorization=" + token
    );
    this.ticketSelectedRows = [];
    this.ticketST.reload();
  }

  // 开票日期事件
  invoiceCreateDateChange(event) {
    this.queryParams.invoiceCreateDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 上传日期事件
  invoiceUploadDateChange(event) {
    this.queryParams.invoiceUploadDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 旅客运输-开票日期事件
  invoiceTicketCreateDateChange(event) {
    this.ticketQueryParams.invoiceCreateDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 旅客运输-上传日期事件
  invoiceTicketUploadDateChange(event) {
    this.ticketQueryParams.invoiceUploadDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 旅客运输-搜索
  ticketSearch() {
    this.ticketQueryParams.page = 1;
    this.getTicketList();
  }

  // 旅客运输-重置
  ticketCancel() {
    this.ticketQueryParams = {
      page: 1,
      limit: 10,
      reportNumber: '',
      fields: '',
      invoiceCreateDateArray: '',
      invoiceUploadDateArray: '',
      name: '',
      amountBegin: '',
      amountEnd: '',
      ocrStatus: '',
    };
    this.ticketCreateDate = '';
    this.ticketUploadDate = '';
    this.getTicketList();
  }

  // tab切换
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 旅客运输-分页
  ticketPagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.ticketQueryParams.page !== event.pi) {
        this.ticketQueryParams.page = event.pi;
        this.getTicketList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.ticketQueryParams.limit !== event.ps) {
        this.ticketQueryParams.limit = event.ps;
        this.ticketQueryParams.page = 1;
        this.getTicketList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.ticketSelectedRows = event.checkbox!;
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }

}
