import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import * as format from 'date-fns/format';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-expense-add',
  templateUrl: './expense-add.component.html',
})
export class ExpenseAddComponent implements OnInit {
  // i: any;
  loading = false;
  data: any = {
    "employeeNumber": "",
    "applyTime": "",
    "applyUser": "",
    "expenseType": "",
    "amount": ""
  }
  employee : any = {
    "employeeNumber": "",
    "employeeName": ""
  }
  employeeDataOption : any = [];
  typeOption : any = [
    "Airline Fees",
    "Business Entertaining - Client/Supplier",
    "Business Meals Staff",
    "Conference Delegate Fees",
    "Currency Exchange Fees",
    "Internet/Online Fees",
    "Laundry",
    "Marketing/Promotional Costs",
    "Miscellaneous",
    "Mobile Phone Calls",
    "Office Supplies/Software",
    "Other legal fees",
    "Passport/Visa Fees - Business Travel",
    "Postage",
    "Printing/Photocopying",
    "Staff Entertaining",
    "Taxi"
  ];
  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.getEmployeeList();
  }

  save() {
    this.loading = true;
    this.data.employeeNumber = this.employee.employeeNumber;
    this.data.applyUser = this.employee.employeeName;
    this.http.post('/input/oaexpenseinfo/save',this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('新增成功');
        this.modal.close(true);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 创建时间处理事件
  createTimeChange(event) {
    this.data.applyTime = event ? format(event, 'YYYY-MM-DD') : '';
  }

  getEmployeeList = async () => {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.http.post('/sys/employee/list', {
      }).subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res && res.msg === 'success') {
          this.employeeDataOption = res.data;
          resolve(res.data);
        } else {
          this.employeeDataOption = [];
          resolve(null);
        }
      }, error => {
        this.employeeDataOption = [];
        this.loading = false;
        console.log('HTTP Error', error);
        resolve(null);
      });
    });
  }

  close() {
    this.modal.destroy();
  }
}
