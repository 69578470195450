import { Component, OnInit, ViewChild } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';


@Component({
  selector: 'app-input-transfer-out-transfer',
  templateUrl: './transfer.component.html',
})
export class InputTransferOutTransferComponent implements OnInit {
  record: any = {};
  i: any;
  ptype = 0; // 页面类型，默认0新增;1编辑
  loading = false;
  data: any = {
    'invoiceIds': '',
    'invoiceTransOutType': '',
  }
  // 转出类型
  transferTypeOption = [
    { value: '银行手续费', label: '银行手续费' },
    { value: '内部交易', label: '内部交易' },
    { value: '总分公司交易', label: '总分公司交易' },
    { value: '礼品类-视同销售(科目)', label: '礼品类-视同销售(科目)' },
    { value: '礼品类-视同销售(供应商)', label: '礼品类-视同销售(供应商)' },
    { value: '礼品类-视同销售(备注)', label: '礼品类-视同销售(备注)' },
    { value: '礼品类-视同销售(关键字)', label: '礼品类-视同销售(关键字)' },
    { value: '福利类-常规', label: '福利类-常规' },
    { value: '福利类-其他', label: '福利类-其他' },
    { value: '福利费-餐饮住宿', label: '福利费-餐饮住宿' },
    { value: '福利费-洗衣住宿', label: '福利费-洗衣住宿' },
    { value: '免税项目-出口货物材料采购', label: '免税项目-出口货物材料采购' },
    { value: '免税项目-水电煤', label: '免税项目-水电煤' },
    { value: '免税项目-免税产品', label: '免税项目-免税产品' },
    { value: '简易征收', label: '简易征收' },
    { value: '红字发票', label: '红字发票' },
    { value: '多重标记', label: '多重标记' }
  ]

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    // 获取页面类型和id
    if (this.i) {
      this.ptype = this.i.ptype;
      this.data.invoiceIds = this.i.invoiceIds;
    }
  }

  // 保存信息
  save() {
    this.msgSrv.success('操作成功');
    /* this.loading = true;
    this.http.post('/input/invoice/updateInvoiceTransOutType', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('操作成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    }); */
  }

  close() {
    this.modal.destroy();
  }
}
