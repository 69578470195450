import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { InputTransferOutConfigurationAddComponent } from '../configuration-add/configuration-add.component';
import { DialogService } from 'src/app/service/dialog.service';
import { UploadXHRArgs, NzModalService, NzMessageService } from 'ng-zorro-antd';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-input-transfer-out-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.less'],
})
export class InputTransferOutConfigurationComponent implements OnInit {
  loading = false;
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '序号', type: 'no', i18n: 'st.no', },
    { title: '转出科目', index: 'category', i18n: 'st.transferSubject', },
    { title: '税收科目', index: 'item', i18n: 'st.taxSubject', },
    { title: '创建时间', index: 'createTime', type: 'date', dateFormat: 'YY-MM-DD', i18n: 'st.createTime', },
    {
      title: '操作',
      i18n: 'st.operate',
      buttons: [
        {
          text: '删除',
          i18n: 'st.operate.delete',
          paramName: 'i',
          pop: true,
          popTitle: '删除操作不可恢复，确认删除吗？',
          click: (item: any) => this.delete(item.id),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    invoiceResponsible: '',
    goodsCategory: '',
  };
  importAction = "/input/invoiceResponsible/importSap";

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalSrv: NzModalService,
    public msg: NzMessageService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取列表
  getList() {
    this.loading = true;
    this.http.get('/input/invoiceTransOut/list', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        if (data.inputTransOutCategoryEntities.length > 0) {
          this.rows = data.inputTransOutCategoryEntities;
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 新增
  add() {
    this.modal.static(InputTransferOutConfigurationAddComponent, { i: { ptype: 0 } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 批量导入上传
  importUpload = (item: UploadXHRArgs) => {
    this.loading = true;
    const url = item.action!;
    const formData = new FormData();
    formData.set("file", item.file as any);
    return this.http.post(url, formData, { headers: new HttpHeaders().set("content-type", "multipart/form-data") }).subscribe(data => {
      this.loading = false;
      if (data.msg === 'success') {
        this.modalSrv.success({
          nzTitle: '上传成功',
          nzOnOk: () => this.getList()
        });
      } else {
        this.modalSrv.error({
          nzTitle: '上传失败',
          nzOnOk: () => console.log('Info OK')
        });
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    }
    );
  };

  // 删除
  delete(id) {
    this.loading = true;
    this.http.post('/input/invoiceResponsible/delete?ids=' + id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('删除成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete() {
    this.modalSrv.confirm({
      nzTitle: '删除操作不可恢复，确认删除吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.id);
          })
          const ids = idList.join(',');
          this.delete(ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('批量删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 批量导出
  bulkExport() {
    this.loading = true;
    this.http.get('/input/invoiceResponsible/exportSap').subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      invoiceResponsible: '',
      goodsCategory: '',
    };
    this.getList();
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }

}
