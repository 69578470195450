import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { InputPurchaseOrderRefundComponent } from '../purchase-order/refund/refund.component';
import { InputTransferOutTransferComponent } from './transfer/transfer.component';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { type } from 'os';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-input-transfer-out',
  templateUrl: './transfer-out.component.html',
  styleUrls: ['./transfer-out.component.less'],
})
export class InputTransferOutComponent implements OnInit {
  saleTabs: any[] = [{ key: 'purchaseOrder', id: 1, show: true }, { key: 'expenseAccount', id: 2 }];
  // 采购单数据
  purchaseShow = false;
  purchaseMore = 'down';
  purchaseSelectedRows: STData[] = []; // 采购单选中数据
  purchaseRows: any = [
    {
      'id': '1', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '认证失败', 'threeErrorDescription': '请修改发票信息,重新联系管理员进行验真操作', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '2', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '待认证', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '3', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '认证失败', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '4', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '待认证', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '5', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '认证失败', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '6', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '认证失败', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '7', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '认证失败', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '8', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '认证失败', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '9', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '认证失败', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '10', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '认证失败', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '11', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceBatchStatus': '认证失败', 'threeErrorDescription': '请修改发票信息', 'invoiceUploadDate': '2019-11-1', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
  ];
  @ViewChild('purchaseSt', { static: false }) purchaseSt: STComponent;
  purchaseColumns: STColumn[] = [
    { title: '批次号', index: 'batchNumber', i18n: 'st.batchNumber', width: 200 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 200 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 200 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '上传人', index: 'createUserName', i18n: 'st.createUserName', width: 50 },
    { title: '上传日期', index: 'invoiceUploadDate', i18n: 'st.invoiceUploadDate', width: 100 },
    { title: '批次状态', index: 'invoiceBatchStatus', i18n: 'st.invoiceBatchStatus', render: 'invoiceBatchStatus', width: 100 },
    { title: '详情', index: 'threeErrorDescription', i18n: 'st.threeErrorDescription', render: 'threeErrorDescription', width: 100 },
  ];
  // 嵌套发票列表
  @ViewChild('purchaseExpendst', { static: false }) purchaseExpendst: STComponent;
  purchaseExpendColumns: STColumn[] = [
    { title: '', index: 'invoiceNumber', type: 'checkbox', className: 'st-checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', i18n: 'st.invoiceTotalPrice', width: 200 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', i18n: 'st.invoiceFreePrice', width: 200 },
    { title: '税额', index: 'invoiceTaxPrice', i18n: 'st.invoiceTaxPrice', width: 50 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 50 },
    { title: '开票日期', index: 'invoiceCreateDate', i18n: 'st.invoiceCreateDate', width: 100 },
    { title: '发票类型', index: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '入账日期', index: 'entryDate', width: 100 },
    { title: '上传日期', index: 'invoiceUploadDate', i18n: 'st.invoiceUploadDate', width: 100 },
    { title: '上传方式', index: 'invoiceUploadType', i18n: 'st.invoiceUploadType', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 100,
      buttons: [
        {
          text: '转出',
          i18n: 'st.operate.transfer',
          paramName: 'i',
          iif: (item) => item.invoiceStatus === '待认证',
          click: (item: any) => this.transfer(item.id, 0),
        },
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          paramName: 'i',
          iif: (item) => item.invoiceStatus === '认证成功',
          click: (item: any) => this.edit(item.id, 0),
        },
        {
          text: '...',
          paramName: 'i',
          children: [
            {
              text: '退票',
              i18n: 'st.operate.refund',
              paramName: 'i',
              click: (item: any) => this.refund(item.id, 0),
            },
            {
              text: '删除',
              i18n: 'st.operate.delete',
              paramName: 'i',
              click: (item: any) => this.delete(item.id, 0),
            },
          ]
        },
      ]
    }
  ];
  purchaseQueryParams: any = {
    page: 1,
    limit: 10,
    invoiceUploadDate: '',
    createUserName: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceEntity: '',
    invoiceFromTo: '',
    invoiceCreateDate: '',
    invoiceStatus: '',
    invoiceTotalPrice: '',
  };

  // 报销单数据
  expenseShow = false;
  expenseMore = 'down';
  expenseSelectedRows: STData[] = []; // 采购单选中数据
  expenseRows: any = [
    {
      'id': '1', 'expenseNumber': '2020021001', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '待报销', invoiceType: '增值税发票', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '5800000.00', 'invoiceFreePrice': '56000000.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '2', 'expenseNumber': '2020021002', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '已报销', invoiceType: '非增值税票据', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '3', 'expenseNumber': '2020021003', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '异常', invoiceType: '增值税发票', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '4', 'expenseNumber': '2020021004', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '超额', invoiceType: '增值税发票', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '5', 'expenseNumber': '2020021005', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '超额', invoiceType: '非增值税票据', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '6', 'expenseNumber': '2020021006', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '待报销', invoiceType: '非增值税票据', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '7', 'expenseNumber': '2020021007', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '待报销', invoiceType: '增值税发票', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '8', 'expenseNumber': '2020021008', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '待报销', invoiceType: '增值税发票', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '9', 'expenseNumber': '2020021009', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '待报销', invoiceType: '非增值税票据', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '10', 'expenseNumber': '20200210010', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '待报销', invoiceType: '非增值税票据', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '11', 'expenseNumber': '20200210011', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '待报销', invoiceType: '增值税发票', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },
    {
      'id': '12', 'expenseNumber': '20200210012', 'applyUser': 'admin', 'endUser': 'admin', 'applyTime': '2020-01-20', 'amount': '58.00', 'invoicesAmount': '290908.50', 'expenseStatus': '待报销', invoiceType: '增值税发票', childList: [
        { 'invoiceCode': '031001900411', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900412', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900413', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900414', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '认证成功', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900415', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
        { 'invoiceCode': '031001900416', 'invoiceNumber': '80452098', 'batchNumber': '20200120003', 'createUserName': 'admin', 'invoiceTransOut': '内部交易', 'invoicePurchaserCompany': '普华永道咨询(深圳)有限公司上海分公司', 'invoicePurchaserParagraph': '91310000X073266018', 'invoiceSellCompany': '上海滴滴畅行科技有限公司', 'invoiceSellParagraph': '91310114MA1GW61J6U', 'invoiceTotalPrice': '58.00', 'invoiceFreePrice': '56.31', 'invoiceTaxPrice': '1.69', 'invoiceCheckCode': '799074', 'invoiceCreateDate': '2019-11-18', 'invoiceEntity': '电子发票', 'entryDate': '019-12-1', 'invoiceUploadDate': '2019-11-1', 'invoiceSapType': '采购单', 'invoiceStatus': '待认证', 'invoiceUploadType': '手动导入', 'invoiceVerifyTruth': '自动验真' },
      ]
    },

  ];
  @ViewChild('expenseSt', { static: false }) expenseSt: STComponent;
  expenseColumns: STColumn[] = [
    { title: '报销单号', index: 'expenseNumber', i18n: 'st.expenseNumber', width: 200 },
    { title: '申请者', index: 'applyUser', i18n: 'st.applyUser', width: 100 },
    { title: '报销者', index: 'endUser', i18n: 'st.endUser', width: 100 },
    { title: '申请时间', index: 'applyTime', i18n: 'st.applyTime', type: 'date', dateFormat: 'YYYY-MM-DD HH:mm:ss', width: 200 },
    { title: '报销金额', index: 'amount', i18n: 'st.amount', width: 100 },
    { title: '已关联票据金额', index: 'invoicesAmount', i18n: 'st.invoicesAmount', width: 200 },
    { title: '报销单状态', index: 'expenseStatus', i18n: 'st.expenseStatus', render: 'expenseStatus', width: 200 },
  ];
  // 嵌套发票列表
  @ViewChild('expenseExpendst', { static: false }) expenseExpendst: STComponent;
  expenseExpendColumns: STColumn[] = [
    { title: '', index: 'invoiceNumber', type: 'checkbox', className: 'st-checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 150 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 150 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', i18n: 'st.invoiceTotalPrice', width: 200 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', i18n: 'st.invoiceFreePrice', width: 200 },
    { title: '税额', index: 'invoiceTaxPrice', i18n: 'st.invoiceTaxPrice', width: 50 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 50 },
    { title: '开票日期', index: 'invoiceCreateDate', i18n: 'st.invoiceCreateDate', width: 100 },
    { title: '发票类型', index: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '上传日期', index: 'invoiceUploadDate', i18n: 'st.invoiceUploadDate', width: 100 },
    { title: '上传人', index: 'createUserName', i18n: 'st.createUserName', width: 50 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 100,
      buttons: [
        {
          text: '转出',
          i18n: 'st.operate.transfer',
          paramName: 'i',
          iif: (item) => item.invoiceStatus === '待认证',
          click: (item: any) => this.transfer(item.id, 1),
        },
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          paramName: 'i',
          iif: (item) => item.invoiceStatus === '认证成功',
          click: (item: any) => this.edit(item.id, 1),
        },
        {
          text: '...',
          paramName: 'i',
          children: [
            {
              text: '退票',
              i18n: 'st.operate.refund',
              paramName: 'i',
              click: (item: any) => this.refund(item.id, 1),
            },
            {
              text: '删除',
              i18n: 'st.operate.delete',
              paramName: 'i',
              click: (item: any) => this.delete(item.id, 1),
            },
          ]
        },
      ]
    }
  ];
  // 列表查询参数
  expenseQueryParams: any = {
    page: 1,
    limit: 10,
    expenseNumber: '',
    expenseStatus: '',
    applyUser: '',
    endUser: '',
    applyTime: '',
    amountBegin: '',
    amountEnd: '',
    invoiceUploadDate: '',
    createUserName: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceEntity: '',
    invoiceFromTo: '',
    invoiceCreateDate: '',
    invoiceStatus: '',
    invoiceTotalPrice: '',
  };
  // 报销单状态
  expenseStatusOption = [
    { value: 0, label: '待报销' },
    { value: 1, label: '已报销' },
    { value: 2, label: '超额' },
    { value: 3, label: '异常' },
  ]
  loading = false;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalSrv: NzModalService,
    private msgSrv: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit() { }

  // 获取采购单列表
  getPurchaseList() {

  }

  // 获取报销单列表
  getExpenseList() {

  }

  // 转出
  transfer(id, ptype) {
    this.modal.static(InputTransferOutTransferComponent, { i: { invoiceIds: id, ptype: 0 } }, 500).subscribe((success) => {
      if (success) {
        // ptype: 0 采购单；1 报销单
        if (ptype === 0) {
          this.getPurchaseList();
        } else {
          this.getExpenseList()
        }
      }
    });
  }

  // 批量转出
  bulkTransfer(ptype) {
    const idList = new Array();
    if (ptype === 0) {
      this.purchaseSelectedRows.map(item => {
        idList.push(item.id);
      })
      this.transfer(idList, ptype);
      this.purchaseSelectedRows = [];
    } else {
      this.expenseSelectedRows.map(item => {
        idList.push(item.id);
      })
      this.transfer(idList, ptype);
      this.expenseSelectedRows = [];
    }
  }

  // 转出编辑
  edit(id, ptype) {
    // todo-转出类型获取
    this.modal.static(InputTransferOutTransferComponent, { i: { invoiceIds: id, ptype: 1 } }, 500).subscribe((success) => {
      if (success) {
        if (ptype === 0) {
          this.getPurchaseList();
        } else {
          this.getExpenseList()
        }
      }
    });
  }

  // 批量转出编辑
  bulkEdit(ptype) {
    const idList = new Array();
    if (ptype === 0) {
      this.purchaseSelectedRows.map(item => {
        idList.push(item.id);
      })
      this.edit(idList, ptype);
      this.purchaseSelectedRows = [];
    } else {
      this.expenseSelectedRows.map(item => {
        idList.push(item.id);
      })
      this.edit(idList, ptype);
      this.expenseSelectedRows = [];
    }
  }

  // 退票
  refund(id, ptype) {
    this.modal.static(InputPurchaseOrderRefundComponent, { i: { invoiceId: id } }, 500).subscribe((success) => {
      if (success) {
        if (ptype === 0) {
          this.getPurchaseList();
        } else {
          this.getExpenseList()
        }
      }
    });
  }

  // 批量退票
  bulkRefund(ptype) {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">批量退票</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = new Array();
          if (ptype === 0) {
            this.purchaseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.refund(ids, ptype);
            this.purchaseSelectedRows = [];
          } else {
            this.expenseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.refund(ids, ptype);
            this.expenseSelectedRows = [];
          }
          resolve(true);
        }).catch(() => console.log('退票失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          if (ptype === 0) {
            this.purchaseSelectedRows = [];
            this.purchaseSt.reload();
          } else {
            this.expenseSelectedRows = [];
            this.expenseSt.reload();
          }
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 删除
  delete(id, ptype) {
    this.loading = true;
    const idList = Array.isArray(id) ? id : [id];
    this.http.post('/input/invoice/delete', (idList)).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('删除成功');
        if (ptype === 0) {
          this.getPurchaseList();
        } else {
          this.getExpenseList();
        }
      } else {
        if (ptype === 0) {
          this.purchaseSt.reload();
        } else {
          this.expenseSt.reload();
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete(ptype) {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">删除</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = new Array();
          if (ptype === 0) {
            this.purchaseSelectedRows.map(item => {
              idList.push(item.id);
            })
            this.delete(idList, ptype);
            this.purchaseSelectedRows = [];
          } else {
            this.expenseSelectedRows.map(item => {
              idList.push(item.id);
            })
            this.delete(idList, ptype);
            this.expenseSelectedRows = [];
          }
          resolve(true);
        }).catch(() => console.log('删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          if (ptype === 0) {
            this.purchaseSelectedRows = [];
            this.purchaseSt.reload();
          } else {
            this.expenseSelectedRows = [];
            this.expenseSt.reload();
          }
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // tab切换
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 采购单-搜索
  purchaseSearch() {

  }

  // 报销单-搜索
  expenseSearch() {

  }

  // 采购单-多选框禁用
  purchaseDisabledCheckbox(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.disabled = index === 0;
      return i;
    });
  }

  // 报销单-多选框禁用
  expenseDisabledCheckbox(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.disabled = index === 0;
      return i;
    });
  }

  // 采购单-重置
  purchaseCancel() {

  }

  // 报销单-重置
  expenseCancel() {

  }

  // 采购单-展开
  purchaseShowOrHidden() {
    this.purchaseShow = !this.purchaseShow;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.purchaseShow) {
      this.purchaseMore = 'up';
    } else {
      this.purchaseMore = 'down';
    }
  }

  // 报销单-展开
  expenseShowOrHidden() {
    this.expenseShow = !this.expenseShow;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.expenseShow) {
      this.expenseMore = 'up';
    } else {
      this.expenseMore = 'down';
    }
  }

  // 采购单-分页
  purchasePagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.purchaseQueryParams.page !== event.pi) {
        this.purchaseQueryParams.page = event.pi;
        this.getPurchaseList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.purchaseQueryParams.limit !== event.ps) {
        this.purchaseQueryParams.limit = event.ps;
        this.purchaseQueryParams.page = 1;
        this.getPurchaseList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.purchaseSelectedRows = event.checkbox!;
    }
  }

  // 采购单-分页
  expensePagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.expenseQueryParams.page !== event.pi) {
        this.expenseQueryParams.page = event.pi;
        this.getExpenseList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.expenseQueryParams.limit !== event.ps) {
        this.expenseQueryParams.limit = event.ps;
        this.expenseQueryParams.page = 1;
        this.getExpenseList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.expenseSelectedRows = event.checkbox!;
    }
  }

}
