import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { AdminEmployeeEditComponent } from './edit/edit.component';
import { NzModalService, NzMessageService } from 'ng-zorro-antd';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-admin-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.less'],
})
export class AdminEmployeeComponent implements OnInit {
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    // { title: '序号', index: 'userId', type: 'checkbox' },
    { title: '员工编号', index: 'employeeNumber'},
    { title: '员工姓名', index: 'employeeName'},
    { title: '创建时间', index: 'createTime'},
    { title: '更新时间', index: 'updateTime'},
    { title: '启用状态', index: 'status', render: 'status', acl: { ability: ['sys:user:delete'] }, i18n: 'st.status' },
    {
      title: '操作',
      i18n: 'st.operate',
      className: 'table-actions',
      buttons: [
        {
          text: '编辑',
          i18n: 'st.operate.edit',
          type: 'modal',
          size: 500,
          component: AdminEmployeeEditComponent,
          paramName: 'i',
          acl: { ability: ['sys:employee:update'] },
          click: () => this.getList(),
        },
        /* {
          text: '删除',
          pop: true,
          popTitle: '删除操作不可恢复，确认删除吗？',
          acl: { ability: ['sys:user:delete'] },
          click: (item: any) => this.delete(item.userId),
        } */
      ],
    },
  ];
  queryParams: any = {
    page: 1,
    limit: 10,
    employeeName: '',
    employeeNumber: ''
  };
  pageTotal: any = '';
  selectedRows: STData[] = []; // 选中删除数据
  loading = false;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private modalSrv: NzModalService,
    public msg: NzMessageService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.loading = true;
    this.http.post('/sys/employee/page', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
        this.cdr.detectChanges();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  add() {
    this.modal.static(AdminEmployeeEditComponent, { i: { id: 0 } }, 500).subscribe((success) => {
      if (success) {
        this.getList();
      }
    });
  }

  // 删除
  delete(userId) {
    this.loading = true;
    this.http.delete('/sys/user/delete?userIds=' + userId).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.info('删除成功');
        this.getList();
      } else {
        this.msg.info(res.msg);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete() {
    this.modalSrv.confirm({
      nzTitle: '删除操作不可恢复，确认删除吗？',
      nzWidth: 500,
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.userId);
          })
          const ids = idList.join(',');
          this.delete(ids);
          resolve(true);
        }).catch(() => console.log('设置失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });

  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 更新启用状态
  changeStatus(event, id) {
    this.loading = true;
    let status;
    // true 开启
    if (event) {
     status = 1;
    } else {
     status = 0;
    }
    this.http.post('/sys/employee/update',{id,status}).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        if(status === 1){
          this.msg.info("状态变更为启用");
        }else {
          this.msg.info("状态变更为禁用");
        }
        this.getList();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    })
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }
}
