import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService, NzCascaderOption } from 'ng-zorro-antd';
import { _HttpClient, ModalHelper, SettingsService } from '@delon/theme';
import { SFSchema, SFUISchema } from '@delon/form';
import { DialogService } from 'src/app/service/dialog.service';

@Component({
  selector: 'app-admin-employee-edit',
  templateUrl: './edit.component.html',
})
export class AdminEmployeeEditComponent implements OnInit {
  i: any;
  data: any = {
    "employeeName": "",
    "employeeNumber": "",
  }
  depts: string;
  // expandKeys: any[] = [];
  nzOptions: NzCascaderOption[] = [];
  loading = false;
  userNameFlag = false; // 用户名重名检测标识
  employeeNumber = ''; // 编辑用户名
  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private modalHelper: ModalHelper,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    public settingsService: SettingsService,
  ) { }

  ngOnInit(): void {
    if(this.i.id){
      this.data.employeeName = this.i.employeeName;
      this.data.employeeNumber = this.i.employeeNumber
    }
  }

  // 添加/编辑
  saveOrUpdate() {
    this.loading = true;
    // 添加
    if (!this.i.id) {
      this.http.put('/sys/employee/save', this.data).subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msgSrv.success('保存成功');
          this.modal.close(true);
        } else {
          this.msgSrv.error(res.msg);
          this.modal.close(false);
        }
        this.cdr.detectChanges();
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    } else {
      // 修改
      this.http.post('/sys/employee/update', this.data).subscribe(res => {
        this.loading = false;
        this.dialogService.getErrorCode(res);
        if (res.code === 0) {
          this.msgSrv.success('编辑成功');
          this.modal.close(true);
        } else {
          this.msgSrv.error(res.msg);
          this.modal.close(false);
        }
        this.cdr.detectChanges();
      }, error => {
        this.loading = false;
        console.log('HTTP Error', error);
      });
    }

  }

  // 检测添加用户名是否重名
  checkUserName() {
    if (this.data.employeeNumber !== this.employeeNumber) {
      this.http.get('/sys/employee/checkNumber?employeeNumber=' + this.data.employeeNumber).subscribe(res => {
        // tslint:disable-next-line: prefer-conditional-expression
        if (res.data <= 0 ) {
          this.userNameFlag = false;
        } else {
          this.userNameFlag = true;
        }
      }, error => {
        console.log('HTTP Error', error);
      });
    } else {
      this.userNameFlag = false;
    }

  }
  checkName() {
    if (this.data.employeeNumber === '') {
      this.userNameFlag = false;
    }
  }

  close() {
    this.modal.destroy();
  }
}
