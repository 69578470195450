import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STColumn, STComponent, STData } from '@delon/abc';
import { SFSchema } from '@delon/form';
import { InputPurchaseOrderRefundComponent } from '../purchase-order/refund/refund.component';
import { DialogService } from 'src/app/service/dialog.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { clientConfig } from './../../../../environments/client-config';
import * as format from 'date-fns/format';
import { ACLService } from '@delon/acl';

@Component({
  selector: 'app-input-certification',
  templateUrl: './certification.component.html',
  styleUrls: ['./certification.component.less'],
})
export class InputCertificationComponent implements OnInit {
  saleTabs: any[] = [{ key: 'purchaseOrder', id: 1, show: true }, { key: 'expenseAccount', id: 2 }, { key: 'singleAccount', id: 3 }];
  // 采购单数据
  purchaseShow = false;
  purchaseMore = 'down';
  purchaseSelectedRows: STData[] = []; // 采购单选中数据
  purchaseRows: any = [];
  @ViewChild('purchaseSt', { static: false }) purchaseSt: STComponent;
  purchaseColumns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '批次号', index: 'batchNumber', render: 'batchNumber', i18n: 'st.batchNumber', width: 100 },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 150 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 150 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 200 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 200 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 50 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 100 },
    { title: '开票日期', index: 'invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 150 },
    { title: '发票类型', index: 'invoiceEntity', render: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '上传日期', index: 'invoiceUploadDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 150 },
    { title: '上传人', index: 'createUserName', i18n: 'st.createUserName', width: 100 },
    { title: '上传方式', index: 'invoiceUploadType', i18n: 'st.invoiceUploadType', render: 'invoiceUploadType', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 120 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 130,
      buttons: [
        {
          text: '勾选',
          i18n: 'st.operate.check',
          paramName: 'i',
          pop: true,
          popTitle: '确认进行勾选操作吗？',
          iif: (item) => item.invoiceStatus === '9',
          click: (item: any) => this.check(item.id, 0),
        },
        {
          text: '撤销勾选',
          i18n: 'st.operate.uncheck',
          paramName: 'i',
          pop: true,
          popTitle: '确认进行撤销勾选操作吗？',
          iif: (item) => item.invoiceStatus === '10',
          click: (item: any) => this.uncheck(item.id, 0),
        },
        {
          text: '...',
          paramName: 'i',
          iif: (item) => item.invoiceStatus === '9' || item.invoiceStatus === '10',
          children: [
            {
              text: '退票',
              i18n: 'st.operate.refund',
              paramName: 'i',
              pop: true,
              popTitle: '确认要进行退票操作吗？',
              iif: item => item.invoiceStatus > 1 && item.invoiceStatus < 10,
              click: (item: any) => this.refund(item.id, 0),
            },
            {
              text: '删除',
              i18n: 'st.operate.delete',
              paramName: 'i',
              pop: true,
              popTitle: '确认进行删除操作吗？',
              click: (item: any) => this.delete(item.id, 0),
            },
          ]
        },
      ]
    }
  ];
  purchaseQueryParams: any = {
    page: 1,
    limit: 10,
    invoiceBatchNumber: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceStatus: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCreateDateArray: '',
    invoiceTotalPriceBegin: '',
    invoiceTotalPriceEnd: '',
    invoiceFromto: '采购订单',
    invoiceEntity: '',
    createUserName: '',
    invoiceUploadDateArray: '',
  };
  createDate: '';
  invoiceCreateDate: '';
  purchasePageTotal = 0;

  // 报销单数据
  expenseShow = false;
  expenseMore = 'down';
  expenseSelectedRows: STData[] = []; // 采购单选中数据
  expenseRows: any = [];
  @ViewChild('expenseSt', { static: false }) expenseSt: STComponent;
  expenseColumns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '报销单号', index: 'expressNumber', render: 'expressNumber', i18n: 'st.expenseNumber', width: 100 },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 150 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 150 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 200 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 200 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 50 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 100 },
    { title: '开票日期', index: 'invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 150 },
    { title: '发票类型', index: 'invoiceEntity', render: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '上传日期', index: 'invoiceUploadDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 150 },
    { title: '上传人', index: 'createUserName', i18n: 'st.createUserName', width: 100 },
    { title: '上传方式', index: 'invoiceUploadType', i18n: 'st.invoiceUploadType', render: 'invoiceUploadType', width: 100 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 120 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 100,
      buttons: [
        {
          text: '勾选',
          i18n: 'st.operate.check',
          paramName: 'i',
          pop: true,
          popTitle: '确认进行勾选操作吗？',
          iif: (item) => item.invoiceStatus === '9',
          click: (item: any) => this.check(item.id, 1),
        },
        {
          text: '撤销勾选',
          i18n: 'st.operate.uncheck',
          paramName: 'i',
          pop: true,
          popTitle: '确认进行撤销勾选操作吗？',
          iif: (item) => item.invoiceStatus === '10',
          click: (item: any) => this.uncheck(item.id, 1),
        },
        {
          text: '...',
          paramName: 'i',
          iif: (item) => item.invoiceStatus === '9' || item.invoiceStatus === '10',
          children: [
            {
              text: '退票',
              i18n: 'st.operate.refund',
              paramName: 'i',
              pop: true,
              popTitle: '确认要进行退票操作吗？',
              iif: item => item.invoiceStatus > 1 && item.invoiceStatus < 10,
              click: (item: any) => this.refund(item.id, 1),
            },
            {
              text: '删除',
              i18n: 'st.operate.delete',
              paramName: 'i',
              pop: true,
              popTitle: '确认进行删除操作吗？',
              click: (item: any) => this.delete(item.id, 1),
            },
          ]
        },
      ]
    }
  ];
  // 列表查询参数
  expenseQueryParams: any = {
    page: 1,
    limit: 10,
    expressNumber: '',
    invoiceCode: '',
    invoiceNumber: '',
    invoiceStatus: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCreateDateArray: '',
    invoiceTotalPriceBegin: '',
    invoiceTotalPriceEnd: '',
    invoiceFromto: '报销单管理',
    invoiceEntity: '',
    createUserName: '',
    invoiceUploadDateArray: '',
  };
  applyTime: '';
  invoiceUploadDate: '';
  expenseCreateDate: '';
  expensePageTotal = 0;
  // 单票认证
  show = false;
  more = 'down';
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  batchList: any = [{ 'id': '', 'invoiceBatchNumber': '' }]; // 采购单号列表
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '发票代码', index: 'invoiceCode', i18n: 'st.invoiceCode', width: 100 },
    { title: '发票号码', index: 'invoiceNumber', i18n: 'st.invoiceNumber', width: 100 },
    { title: '购方企业名称', index: 'invoicePurchaserCompany', i18n: 'st.invoicePurchaserCompany', width: 150 },
    { title: '购方税号', index: 'invoicePurchaserParagraph', i18n: 'st.invoicePurchaserParagraph', width: 100 },
    { title: '销方企业名称', index: 'invoiceSellCompany', i18n: 'st.invoiceSellCompany', width: 150 },
    { title: '销方税号', index: 'invoiceSellParagraph', i18n: 'st.invoiceSellParagraph', width: 100 },
    { title: '票面金额(含税)', index: 'invoiceTotalPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTotalPrice', width: 200 },
    { title: '票面金额(不含税)', index: 'invoiceFreePrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceFreePrice', width: 200 },
    { title: '税额', index: 'invoiceTaxPrice', type: 'number', numberDigits: '.2-2', className: 'text-left', i18n: 'st.invoiceTaxPrice', width: 50 },
    { title: '校验码', index: 'invoiceCheckCode', i18n: 'st.invoiceCheckCode', width: 100 },
    { title: '开票日期', index: 'invoiceCreateDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceCreateDate', width: 150 },
    { title: '发票类型', index: 'invoiceEntity', render: 'invoiceEntity', i18n: 'st.invoiceEntity', width: 100 },
    { title: '上传日期', index: 'invoiceUploadDate', type: 'date', dateFormat: 'YYYY-MM-DD', i18n: 'st.invoiceUploadDate', width: 150 },
    { title: '上传人', index: 'createUserName', i18n: 'st.createUserName', width: 100 },
    { title: '上传方式', index: 'invoiceUploadType', i18n: 'st.invoiceUploadType', render: 'invoiceUploadType', width: 100 },
    { title: '异常信息', index: 'invoiceErrorDescription', render: 'invoiceErrorDescription', width: 120 },
    { title: '发票状态', index: 'invoiceStatus', i18n: 'st.invoiceStatus', render: 'invoiceStatus', fixed: 'right', width: 100 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 100,
      buttons: [
        {
          text: '勾选',
          i18n: 'st.operate.check',
          paramName: 'i',
          pop: true,
          popTitle: '确认进行勾选操作吗？',
          iif: (item) => item.invoiceStatus === '9',
          click: (item: any) => this.check(item.id, 2),
        },
        {
          text: '撤销勾选',
          i18n: 'st.operate.uncheck',
          paramName: 'i',
          pop: true,
          popTitle: '确认进行撤销勾选操作吗？',
          iif: (item) => item.invoiceStatus === '10',
          click: (item: any) => this.uncheck(item.id, 2),
        },
        {
          text: '...',
          paramName: 'i',
          iif: (item) => item.invoiceStatus === '9' || item.invoiceStatus === '10',
          children: [
            {
              text: '退票',
              i18n: 'st.operate.refund',
              paramName: 'i',
              click: (item: any) => this.refund(item.id, 2),
            },
            {
              text: '删除',
              i18n: 'st.operate.delete',
              paramName: 'i',
              pop: true,
              popTitle: '确认进行删除操作吗？',
              click: (item: any) => this.delete(item.id, 2),
            },
          ]
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    invoiceCode: '',
    invoiceNumber: '',
    invoiceStatus: '',
    invoicePurchaserCompany: '',
    invoiceSellCompany: '',
    invoiceCreateDateArray: '',
    invoiceTotalPriceBegin: '',
    invoiceTotalPriceEnd: '',
    invoiceFromto: '单票查询',
    invoiceEntity: '专用发票',
    createUserName: '',
    invoiceUploadDateArray: '',
    menuType: '6',
  };
  pageTotal = 0;
  singleCreateDate: '';
  singleUploadDate: '';
  // 报销单状态
  expenseStatusOption = [
    { value: 0, label: '待报销' },
    { value: 1, label: '已报销' },
    { value: 2, label: '超额' },
    { value: 3, label: '异常' },
  ]
  loading = false;
  invoiceStatusOption = clientConfig.invoiceStatusOption; // 全部发票状态

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    private cdr: ChangeDetectorRef,
    private modalSrv: NzModalService,
    private msgSrv: NzMessageService,
    private dialogService: DialogService,
    private aclService: ACLService,
  ) { }

  ngOnInit() {
    if (this.aclService.canAbility('input:purchase:show')) {
      this.getPurchaseList();
    }
    if (this.aclService.canAbility('input:expense:show')) {
      this.getExpenseList();
    }
    if (this.aclService.canAbility('input:singleInvoice:show')) {
      this.getList();
    }
  }

  // 获取采购单列表
  getPurchaseList() {
    this.loading = true;
    this.http.get('/input/invoice/certificationList', this.purchaseQueryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.purchaseRows = data.page.list;
        this.purchaseQueryParams.page = data.page.currPage;
        this.purchaseQueryParams.limit = data.page.pageSize;
        this.purchasePageTotal = data.page.totalCount;
        this.batchList = data.invoiceBatchEntityList;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 获取报销单列表
  getExpenseList() {
    this.loading = true;
    this.http.get('/input/invoice/certificationList', this.expenseQueryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.expenseRows = data.page.list;
        this.expenseQueryParams.page = data.page.currPage;
        this.expenseQueryParams.limit = data.page.pageSize;
        this.expensePageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 单票认证-列表
  getList() {
    this.loading = true;
    this.http.get('/input/invoice/billList', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.page.list;
        this.batchList = data.invoiceBatchEntityList;
        this.queryParams.page = data.page.currPage;
        this.queryParams.limit = data.page.pageSize;
        this.pageTotal = data.page.totalCount;
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 勾选
  check(id, ptype) {
    this.loading = true;
    this.http.post('/input/invoicesync/deductInvoices?type=1&invoiceIds=' + id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('勾选成功');
        if (ptype === 0) {
          this.getPurchaseList();
        } else if (ptype === 1) {
          this.getExpenseList();
        } else {
          this.getList();
        }
      } else {
        if (ptype === 0) {
          this.purchaseSt.reload();
        } else if (ptype === 1) {
          this.expenseSt.reload();
        } else {
          this.st.reload();
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量勾选
  bulkCheck(ptype) {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">勾选</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = new Array();
          if (ptype === 0) {
            this.purchaseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.check(ids, ptype);
            this.purchaseSelectedRows = [];
          } else if (ptype === 1) {
            this.expenseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.check(ids, ptype);
            this.expenseSelectedRows = [];
          } else {
            this.selectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.check(ids, ptype);
            this.selectedRows = [];
          }
          resolve(true);
        }).catch(() => console.log('勾选失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          if (ptype === 0) {
            this.purchaseSelectedRows = [];
            this.purchaseSt.reload();
          } else if (ptype === 1) {
            this.expenseSelectedRows = [];
            this.expenseSt.reload();
          } else {
            this.selectedRows = [];
            this.st.reload();
          }
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 撤销勾选
  uncheck(id, ptype) {
    this.loading = true;
    this.http.post('/input/invoicesync/deductInvoices?type=6&invoiceIds=' + id).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('撤销勾选成功');
        if (ptype === 0) {
          this.getPurchaseList();
        } else if (ptype === 1) {
          this.getExpenseList();
        } else {
          this.getList();
        }
      } else {
        if (ptype === 0) {
          this.purchaseSt.reload();
        } else if (ptype === 1) {
          this.expenseSt.reload();
        } else {
          this.st.reload();
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量撤销勾选
  bulkUncheck(ptype) {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">撤销勾选</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = new Array();
          if (ptype === 0) {
            this.purchaseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.uncheck(ids, ptype);
            this.purchaseSelectedRows = [];
          } else if (ptype === 1) {
            this.expenseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.uncheck(ids, ptype);
            this.expenseSelectedRows = [];
          } else {
            this.selectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.uncheck(ids, ptype);
            this.selectedRows = [];
          }
          resolve(true);
        }).catch(() => console.log('撤销勾选失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          if (ptype === 0) {
            this.purchaseSelectedRows = [];
            this.purchaseSt.reload();
          } else if (ptype === 1) {
            this.expenseSelectedRows = [];
            this.expenseSt.reload();
          } else {
            this.selectedRows = [];
            this.st.reload();
          }
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 退票
  refund(id, ptype) {
    this.modal.static(InputPurchaseOrderRefundComponent, { i: { invoiceId: id } }, 500).subscribe((success) => {
      if (success) {
        if (ptype === 0) {
          this.getPurchaseList();
        } else if (ptype === 1) {
          this.getExpenseList()
        } else {
          this.getList()
        }
      } else {
        if (ptype === 0) {
          this.purchaseSelectedRows = [];
          this.purchaseSt.reload();
        } else if (ptype === 1) {
          this.expenseSelectedRows = [];
          this.expenseSt.reload();
        } else {
          this.selectedRows = [];
          this.st.reload();
        }
      }
    });
  }

  // 批量退票
  bulkRefund(ptype) {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">批量退票</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = new Array();
          if (ptype === 0) {
            this.purchaseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.refund(ids, ptype);
            this.purchaseSelectedRows = [];
          } else if (ptype === 1) {
            this.expenseSelectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.refund(ids, ptype);
            this.expenseSelectedRows = [];
          } else {
            this.selectedRows.map(item => {
              idList.push(item.id);
            })
            const ids = idList.join(',');
            this.refund(ids, ptype);
            this.selectedRows = [];
          }
          resolve(true);
        }).catch(() => console.log('退票失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          if (ptype === 0) {
            this.purchaseSelectedRows = [];
            this.purchaseSt.reload();
          } else if (ptype === 1) {
            this.expenseSelectedRows = [];
            this.expenseSt.reload();
          } else {
            this.selectedRows = [];
            this.st.reload();
          }
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 删除
  delete(id, ptype) {
    this.loading = true;
    const idList = Array.isArray(id) ? id : [id];
    this.http.post('/input/invoice/delete', (idList)).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('删除成功');
        if (ptype === 0) {
          this.getPurchaseList();
        } else if (ptype === 1) {
          this.getExpenseList();
        } else {
          this.getList();
        }
      } else {
        if (ptype === 0) {
          this.purchaseSt.reload();
        } else if (ptype === 1) {
          this.expenseSt.reload();
        } else {
          this.st.reload();
        }
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量删除
  bulkDelete(ptype) {
    this.modalSrv.confirm({
      nzContent: '确认要进行<font color="red">删除</font>操作吗？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          const idList = new Array();
          if (ptype === 0) {
            this.purchaseSelectedRows.map(item => {
              idList.push(item.id);
            })
            this.delete(idList, ptype);
            this.purchaseSelectedRows = [];
          } else if (ptype === 1) {
            this.expenseSelectedRows.map(item => {
              idList.push(item.id);
            })
            this.delete(idList, ptype);
            this.expenseSelectedRows = [];
          } else {
            this.selectedRows.map(item => {
              idList.push(item.id);
            })
            this.delete(idList, ptype);
            this.selectedRows = [];
          }
          resolve(true);
        }).catch(() => console.log('删除失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          if (ptype === 0) {
            this.purchaseSelectedRows = [];
            this.purchaseSt.reload();
          } else if (ptype === 1) {
            this.expenseSelectedRows = [];
            this.expenseSt.reload();
          } else {
            this.selectedRows = [];
            this.st.reload();
          }
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // tab切换
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  // 采购单-搜索
  purchaseSearch() {
    this.purchaseQueryParams.page = 1;
    this.getPurchaseList();
  }

  // 报销单-搜索
  expenseSearch() {
    this.expenseQueryParams.page = 1;
    this.getExpenseList();
  }

  // 采购单-多选框禁用
  purchaseDisabledCheckbox(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.disabled = index === 0;
      return i;
    });
  }

  // 报销单-多选框禁用
  expenseDisabledCheckbox(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.disabled = index === 0;
      return i;
    });
  }

  // 采购单-重置
  purchaseCancel() {
    this.purchaseQueryParams = {
      page: 1,
      limit: 10,
      invoiceBatchNumber: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceStatus: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCreateDateArray: '',
      invoiceTotalPriceBegin: '',
      invoiceTotalPriceEnd: '',
      invoiceFromto: '采购订单',
      invoiceEntity: '',
      createUserName: '',
      invoiceUploadDateArray: '',
    };
    this.createDate = '';
    this.invoiceCreateDate = '';
    this.getPurchaseList();
  }

  // 报销单-重置
  expenseCancel() {
    this.expenseQueryParams = {
      page: 1,
      limit: 10,
      expressNumber: '',
      invoiceCode: '',
      invoiceNumber: '',
      invoiceStatus: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCreateDateArray: '',
      invoiceTotalPriceBegin: '',
      invoiceTotalPriceEnd: '',
      invoiceFromto: '报销单管理',
      invoiceEntity: '',
      createUserName: '',
      invoiceUploadDateArray: '',
    };
    this.expenseCreateDate = '';
    this.invoiceUploadDate = '';
    this.getExpenseList();
  }

  // 采购单-开票日期事件
  invoiceCreateDateChange1(event) {
    this.purchaseQueryParams.invoiceCreateDateArray = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 采购单-上传日期事件
  invoiceUploadDateChange1(event) {
    this.purchaseQueryParams.invoiceUploadDateArray = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 报销单-申请时间处理事件
  applyTimeDateChange(event) {
    this.expenseQueryParams.applyTime = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 报销单开票日期处理事件
  invoiceUploadDateChange2(event) {
    this.expenseQueryParams.invoiceCreateDateArray = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }
  // 报销单-上传日期处理事件
  invoiceCreateDateChange2(event) {
    this.expenseQueryParams.invoiceUploadDateArray = event.length > 0 ? format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD') : '';
  }

  // 采购单-展开
  purchaseShowOrHidden() {
    this.purchaseShow = !this.purchaseShow;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.purchaseShow) {
      this.purchaseMore = 'up';
    } else {
      this.purchaseMore = 'down';
    }
  }

  // 报销单-展开
  expenseShowOrHidden() {
    this.expenseShow = !this.expenseShow;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.expenseShow) {
      this.expenseMore = 'up';
    } else {
      this.expenseMore = 'down';
    }
  }

  // 报销单默认展开事件
  expenseDataChange(data: STData[]) {
    return data.map((i: STData, index: number) => {
      i.expand = index === 0;
      return i;
    });
  }

  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      invoiceCode: '',
      invoiceNumber: '',
      invoiceStatus: '',
      invoicePurchaserCompany: '',
      invoiceSellCompany: '',
      invoiceCreateDateArray: '',
      invoiceTotalPriceBegin: '',
      invoiceTotalPriceEnd: '',
      invoiceFromto: '单票查询',
      invoiceEntity: '',
      createUserName: '',
      invoiceUploadDateArray: '',
      menuType: '6',
    };
    this.singleCreateDate = '';
    this.singleUploadDate = '';
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 开票日期事件
  invoiceCreateDateChange(event) {
    this.queryParams.invoiceCreateDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 上传日期事件
  invoiceUploadDateChange(event) {
    this.queryParams.invoiceUploadDateArray = format(event[0], 'YYYY-MM-DD') + ',' + format(event[1], 'YYYY-MM-DD');
  }

  // 单票认证-分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }

  // 采购单-分页
  purchasePagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.purchaseQueryParams.page !== event.pi) {
        this.purchaseQueryParams.page = event.pi;
        this.getPurchaseList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.purchaseQueryParams.limit !== event.ps) {
        this.purchaseQueryParams.limit = event.ps;
        this.purchaseQueryParams.page = 1;
        this.getPurchaseList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.purchaseSelectedRows = event.checkbox!;
    }
  }

  // 报销单-分页
  expensePagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.expenseQueryParams.page !== event.pi) {
        this.expenseQueryParams.page = event.pi;
        this.getExpenseList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.expenseQueryParams.limit !== event.ps) {
        this.expenseQueryParams.limit = event.ps;
        this.expenseQueryParams.page = 1;
        this.getExpenseList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.expenseSelectedRows = event.checkbox!;
    }
  }

}
