import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NzModalRef, NzMessageService } from 'ng-zorro-antd';
import { _HttpClient } from '@delon/theme';
import { DialogService } from 'src/app/service/dialog.service';
import { DomSanitizer } from '@angular/platform-browser';
import { clientConfig } from './../../../../../environments/client-config';
import * as format from 'date-fns/format';
import { environment } from '@env/environment';

@Component({
  selector: 'app-invoice-invoice-overview-edit-ticket',
  templateUrl: './edit-ticket.component.html',
  styleUrls: ['./edit-ticket.component.less'],
})
export class InvoiceInvoiceOverviewEditTicketComponent implements OnInit {
  loading = false;
  i: any;
  saleTabs: any[] = [{ key: 'basicInfo', id: 1, show: true }, { key: 'invoiceDetails', id: 2 }];
  data: any = {
    id: '',
    reportNumber: '',
    name: '',
    createTime: '', // 上传日期
    fields: '',
    issueDate: '', // 开票日期
    amount: '',
    rate: '',
    tax: '',
  }; // 差旅发票信息
  invoiceImage: any = '';
  type: any = ''; // 发票图片类型pdf、jpg
  invoiceEntityOption = clientConfig.VATITInvoiceTypeOption; // vatit发票类型

  constructor(
    private modal: NzModalRef,
    private msgSrv: NzMessageService,
    public http: _HttpClient,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.getInvoiceInfo();
  }

  // 处理发票图片信息
  getInvoiceInfo() {
    if (this.i) {
      this.data = this.i;
      if (this.data.imageUrl) {
        this.type = this.data.imageUrl.split('.').slice(-1).toString();
        const imageUrl = environment.SERVER_URL + '/' + this.data.imageUrl;
        console.log('VAT-imageUrl', imageUrl);
        this.invoiceImage = this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
      }
    }
  }

  // 更新发票信息
  save() {
    this.loading = true;
    this.http.post('/input/invoice/updateUnformat', this.data).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msgSrv.success('操作成功');
        this.modal.close(true);
      } else {
        this.modal.close(false);
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 开票时间处理
  invoiceCreateDateChange(event) {
    this.data.issueDate = event ? format(event, 'YYYY-MM-DD HH:mm:ss') : '';
  }

  // 上传时间处理
  invoiceUploadDateChange(event) {
    this.data.createTime = event ? format(event, 'YYYY-MM-DD HH:mm:ss') : '';
  }

  // tab切换事件
  salesChange(idx: number) {
    if (this.saleTabs[idx].show !== true) {
      this.saleTabs[idx].show = true;
      this.cdr.detectChanges();
    }
  }

  close() {
    this.modal.destroy();
  }
}
