import { Component, OnInit, ViewChild } from '@angular/core';
import { _HttpClient, ModalHelper } from '@delon/theme';
import { STComponent, STColumn, STData } from '@delon/abc';
import { InputStatisticalSignatureInvoiceStatisticsComponent } from './invoice-statistics/invoice-statistics.component';
import { DialogService } from 'src/app/service/dialog.service';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';

@Component({
  selector: 'app-input-statistical-signature',
  templateUrl: './statistical-signature.component.html',
  styleUrls: ['./statistical-signature.component.less'],
})
export class InputStatisticalSignatureComponent implements OnInit {
  show = false;
  more = 'down';
  loading = false;
  selectedRows: STData[] = []; // 选中数据
  rows: any = [];
  @ViewChild('st', { static: false }) st: STComponent;
  columns: STColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    // { title: '企业编号', index: 'company.companyNumber', i18n: 'st.companyNumber', width: 100 },
    { title: '企业名称', index: 'name', i18n: 'st.companyName', width: 100 },
    { title: '税号', index: 'taxCode', i18n: 'st.taxCode', width: 100 },
    { title: '地址', index: 'companyAddress', i18n: 'st.address', width: 100 },
    { title: '电话', index: 'companyTel', i18n: 'st.tel', width: 100 },
    // { title: '银行', index: 'companyBank', i18n: 'st.bank', width: 100 },
    { title: '银行账号', index: 'company.companyBankAccount', i18n: 'st.bankAccount', width: 100 },
    { title: '统计状态', index: 'company.status', render: 'status', i18n: 'st.statisticsStatus', width: 100 },
    // { title: '申请统计执行状态', index: 'company.applyResult', render: 'applyResult', i18n: 'st.applyResult', width: 180 },
    // { title: '确认统计执行状态', index: 'company.censusResult', render: 'censusResult', i18n: 'st.censusResult', width: 180 },
    { title: '统计月份', index: 'company.statisticsMonth', i18n: 'st.statisticsMonth', width: 100 },
    { title: '统计时间', index: 'company.statisticsTime', type: 'date', dateFormat: 'YY-MM-DD', i18n: 'st.statisticsTime', width: 120 },
    {
      title: '操作',
      i18n: 'st.operate',
      fixed: 'right',
      width: 180,
      buttons: [
        {
          text: '申请统计',
          i18n: 'st.operate.statistics',
          pop: true,
          popTitle: '是否进行统计申请操作？',
          paramName: 'i',
          iif: (item) => item.company.status === '0',
          click: (item: any) => this.statistics(item.taxCode),
        },
        {
          text: '撤销统计',
          i18n: 'st.operate.revocationStatistics',
          paramName: 'i',
          pop: true,
          popTitle: '该公司已发起申请，是否需要撤销后重新申请？',
          iif: (item) => item.company.status === '3',
          click: (item: any) => this.revocationStatistics(item.taxCode),
        },
        {
          text: '统计确认',
          i18n: 'btn.statisticsConfirm',
          paramName: 'i',
          pop: true,
          popTitle: '是否进行统计确认？',
          iif: (item) => item.company.status === '3',
          click: (item: any) => this.confirm(item.taxCode),
        },
        {
          text: '发票统计信息',
          i18n: 'st.operate.invoiceStatistics',
          type: 'modal',
          size: 1100,
          component: InputStatisticalSignatureInvoiceStatisticsComponent,
          paramName: 'i',
          iif: (item) => item.company.status !== '0',
          click: (item: any) => this.getList(),
        },
      ]
    }
  ];
  // 列表查询参数
  queryParams: any = {
    page: 1,
    limit: 10,
    companyNumber: '',
    companyName: '',
    companyDutyParagraph: '',
    statisticsMonth: '',
    applyResult: '',
    censusResult: '',
    statisticsTime: '',
  };
  // 申请统计执行状态
  applyResultOption: [
    { value: "0", label: "待处理" },
    { value: "1", label: "处理成功" },
    { value: "2", label: "未提交申请统计" },
    { value: "3", label: "已提交申请统计" },
    { value: "4", label: "不符合申请生成抵扣统计报表条件" },
    { value: "5", label: "不符合撤销条件" },
    { value: "6", label: "执行异常" },
    { value: "7", label: "正常抵扣发票统计完成" },
    { value: "8", label: "没有符合条件的记录" },
    { value: "9", label: "系统执行中" },
    { value: "10", label: "抵扣统计查询失败" },
    { value: "99", label: "撤销统计执行中" }
  ];
  // 确认统计执行状态
  censusResultOption: [
    { value: "0", label: "待处理" },
    { value: "1", label: "确认成功" },
    { value: "2", label: "未提交申请统计" },
    { value: "4", label: "统计结果不符" },
    { value: "5", label: "确认成功" },
    { value: "6", label: "执行异常" },
    { value: "7", label: "确认失败" },
    { value: "8", label: "不符合提交确认申报条件" },
    { value: "9", label: "系统执行中" },
    { value: "10", label: "签名值错误" }
  ];
  pageTotal = 0;

  constructor(
    private http: _HttpClient,
    private modal: ModalHelper,
    public msg: NzMessageService,
    private modalSrv: NzModalService,
    private dialogService: DialogService,
  ) { }

  ngOnInit() {
    this.getList();
  }

  // 获取列表
  getList() {
    this.loading = true;
    this.http.get('/input/company/statisticsList', this.queryParams).subscribe(data => {
      this.loading = false;
      this.dialogService.getErrorCode(data);
      if (data.msg === 'success') {
        this.rows = data.list.list;
        this.queryParams.page = data.list.currPage;
        this.queryParams.limit = data.list.pageSize;
        this.pageTotal = data.list.totalCount;
        this.rows.map(item => {
          // 地址电话处理
          if (item.company) {
            if (item.company.companyAddressPhone) {
              if (item.company.companyAddressPhone.includes(' ')) {
                item.companyAddress = item.company.companyAddressPhone.split(' ')[0];
                item.companyTel = item.company.companyAddressPhone.split(' ')[1];
              } else {
                item.companyAddress = item.company.companyAddressPhone.slice(0, -13);
                item.companyTel = item.company.companyAddressPhone.slice(-13);
                if (isNaN(item.companyTel.slice(0, 1))) {
                  // 如果是字符串，则添加到companyAddress
                  item.companyAddress += item.companyTel.slice(0, 1);
                  item.companyTel = item.companyTel.slice(1);
                }
              }
            } else {
              item.companyAddress = '';
              item.companyTel = '';
            }
          }
        })
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 申请统计
  statistics(taxNo) {
    this.loading = true;
    this.http.get('/input/invoicesync/statistics?type=1&taxNo=' + taxNo).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('统计申请成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }

  // 批量申请统计
  bulkStatistics() {
    this.modalSrv.confirm({
      nzTitle: '是否进行统计申请操作？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.taxCode);
          })
          const ids = idList.join(',');
          this.statistics(ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('统计申请失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 撤销统计
  revocationStatistics(taxNo) {
    this.loading = true;
    this.http.get('/input/invoicesync/statistics?type=2&taxNo=' + taxNo).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('统计撤销成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);

    });
  }

  // 批量撤销统计
  bulkRevocationStatistics() {
    this.modalSrv.confirm({
      nzTitle: '所选公司已发起申请，是否需要撤销后重新申请？',
      nzIconType: 'warning',
      nzWrapClassName: 'vertical-center-modal',
      nzOnOk: () =>
        // tslint:disable-next-line: no-shadowed-variable
        new Promise((resolve, reject) => {
          // 处理获取的数据id
          const idList = new Array();
          this.selectedRows.map(item => {
            idList.push(item.taxCode);
          })
          const ids = idList.join(',');
          this.revocationStatistics(ids);
          this.selectedRows = [];
          resolve(true);
        }).catch(() => console.log('统计撤销失败')),
      nzOnCancel: () =>
        new Promise((resolve, reject) => {
          this.selectedRows = [];
          this.st.reload();
          resolve(true);
        }).catch(() => console.log('取消失败')),
    });
  }

  // 统计确认
  confirm(taxNo) {
    this.loading = true;
    this.http.get('/input/invoicesync/confirm?taxNo=' + taxNo).subscribe(res => {
      this.loading = false;
      this.dialogService.getErrorCode(res);
      if (res.code === 0) {
        this.msg.success('统计申请成功');
        this.getList();
      } else {
        this.st.reload();
      }
    }, error => {
      this.loading = false;
      console.log('HTTP Error', error);
    });
  }


  // 搜索
  search() {
    this.queryParams.page = 1;
    this.getList();
  }

  // 重置
  cancel() {
    this.queryParams = {
      page: 1,
      limit: 10,
      companyNumber: '',
      companyName: '',
      companyDutyParagraph: '',
      statisticsMonth: '',
      applyResult: '',
      censusResult: '',
      statisticsTime: '',
    };
    this.getList();
  }

  // 展开
  showOrHidden() {
    this.show = !this.show;
    // tslint:disable-next-line: prefer-conditional-expression
    if (this.show) {
      this.more = 'up';
    } else {
      this.more = 'down';
    }
  }

  // 分页
  pagechange(event) {
    // 页码分页事件
    if (event.type === 'pi') {
      if (this.queryParams.page !== event.pi) {
        this.queryParams.page = event.pi;
        this.getList();
      }
    }
    // 每页显示数量分页事件
    if (event.type === 'ps') {
      if (this.queryParams.limit !== event.ps) {
        this.queryParams.limit = event.ps;
        this.queryParams.page = 1;
        this.getList();
      }
    }
    // checkbox选择事件
    if (event.type === 'checkbox') {
      this.selectedRows = event.checkbox!;
    }
  }

}
