import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';
import { InputRoutingModule } from './input-routing.module';
import { InputInvoiceOverviewComponent } from './invoice-overview/invoice-overview.component';
import { InputPurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { InputExpenseAccountComponent } from './expense-account/expense-account.component';
import { InputLedgerBankComponent } from './ledger-bank/ledger-bank.component';
import { InputStatisticalSignatureComponent } from './statistical-signature/statistical-signature.component';
import { InputPurchaseOrderAuthenticityComponent } from './purchase-order/authenticity/authenticity.component';
import { InputRuleSettingComponent } from './rule-setting/rule-setting.component';
import { InputProductComponent } from './product/product.component';
import { InputExpenseAccountAuthenticityComponent } from './expense-account/authenticity/authenticity.component';
import { InputPurchaseOrderCreditComponent } from './purchase-order/credit/credit.component';
import { InputPurchaseOrderCertificationComponent } from './purchase-order/certification/certification.component';
import { InputPurchaseOrderTransferOutComponent } from './purchase-order/transfer-out/transfer-out.component';
import { InputPurchaseOrderAbnormalInvoiceComponent } from './purchase-order/abnormal-invoice/abnormal-invoice.component';
import { InputExpenseAccountAbnormalInvoiceComponent } from './expense-account/abnormal-invoice/abnormal-invoice.component';
import { InputExpenseAccountTransferOutComponent } from './expense-account/transfer-out/transfer-out.component';
import { InputExpenseAccountCertificationComponent } from './expense-account/certification/certification.component';
import { InputExpenseAccountCreditComponent } from './expense-account/credit/credit.component';
import { InputInvoiceOverviewViewComponent } from './invoice-overview/view/view.component';
import { InputPurchaseOrderRefundComponent } from './purchase-order/refund/refund.component';
import { InputPurchaseOrderManualUploadComponent } from './purchase-order/manual-upload/manual-upload.component';
import { InputPurchaseOrderUploadTipComponent } from './purchase-order/upload-tip/upload-tip.component';
import { InputPurchaseOrderRefundInfoComponent } from './purchase-order/refund-info/refund-info.component';
import { InputPurchaseOrderAddtionalInvoiceComponent } from './purchase-order/addtional-invoice/addtional-invoice.component';
import { InputExpenseAccountManualInputComponent } from './expense-account/manual-input/manual-input.component';
import { InputExpenseAccountInvoiceInfoComponent } from './expense-account/invoice-info/invoice-info.component';
import { ExpenseAddComponent } from './expense-account/expense-add/expense-add.component';
import { InputExpenseAccountInvoiceEditComponent } from './expense-account/invoice-edit/invoice-edit.component';
import { InputTransferOutComponent } from './transfer-out/transfer-out.component';
import { InputTransferOutConfigurationComponent } from './transfer-out/configuration/configuration.component';
import { InputTransferOutConfigurationAddComponent } from './transfer-out/configuration-add/configuration-add.component';
import { InputTransferOutTransferComponent } from './transfer-out/transfer/transfer.component';
import { InputCertificationComponent } from './certification/certification.component';
import { InputStatisticalSignatureInvoiceStatisticsComponent } from './statistical-signature/invoice-statistics/invoice-statistics.component';
import { InputConfigurationComponent } from './configuration/configuration.component';
import { InputConfigurationTypeEditComponent } from './configuration/type-edit/type-edit.component';
import { InputPurchaseOrderThreeSingleMatchesComponent } from './purchase-order/three-single-matches/three-single-matches.component';
import { InputPurchaseOrderThreeSingleMatchesMaterialMatchComponent } from './purchase-order/three-single-matches/material-match/material-match.component';
import { InputPurchaseOrderThreeSingleMatchesMatchResultComponent } from './purchase-order/three-single-matches/match-result/match-result.component';
import { InputInvoiceOverviewExpenseOverviewComponent } from './invoice-overview/expense-overview/expense-overview.component';
import { InputInvoiceOverviewMatchComponent } from './invoice-overview/match/match.component';
import { InputExpenseAccountInvoiceViewComponent } from './expense-account/invoice-view/invoice-view.component';
import { InputInvoiceOverviewEditComponent } from './invoice-overview/edit/edit.component';
import { InvoiceInvoiceOverviewEditTicketComponent } from '../invoice/invoice-overview/edit-ticket/edit-ticket.component';
import { InputConfigurationFaultTolerantComponent } from './configuration/fault-tolerant/fault-tolerant.component';
import { InputConfigurationInvoiceVerificationComponent } from './configuration/invoice-verification/invoice-verification.component';
import { InputConfigurationFaultTolerantEditComponent } from './configuration/fault-tolerant/edit/edit.component';

const COMPONENTS = [
  InputInvoiceOverviewComponent,
  InputPurchaseOrderComponent,
  InputExpenseAccountComponent,
  InputLedgerBankComponent,
  InputStatisticalSignatureComponent,
  InputPurchaseOrderAuthenticityComponent,
  InputRuleSettingComponent,
  InputProductComponent,
  InputExpenseAccountAuthenticityComponent,
  InputPurchaseOrderCreditComponent,
  InputPurchaseOrderCertificationComponent,
  InputPurchaseOrderTransferOutComponent,
  InputPurchaseOrderAbnormalInvoiceComponent,
  InputExpenseAccountAbnormalInvoiceComponent,
  InputExpenseAccountTransferOutComponent,
  InputExpenseAccountCertificationComponent,
  InputExpenseAccountCreditComponent,
  InputPurchaseOrderRefundComponent,
  InputPurchaseOrderManualUploadComponent,
  InputPurchaseOrderUploadTipComponent,
  InputTransferOutComponent,
  InputTransferOutConfigurationComponent,
  InputTransferOutConfigurationAddComponent,
  InputCertificationComponent,
  InputConfigurationComponent,
  InputPurchaseOrderThreeSingleMatchesComponent,
  InputPurchaseOrderThreeSingleMatchesMaterialMatchComponent,
  InputInvoiceOverviewExpenseOverviewComponent,
  InputConfigurationFaultTolerantComponent,
  InputConfigurationInvoiceVerificationComponent];
const COMPONENTS_NOROUNT = [
  InputInvoiceOverviewViewComponent,
  InputPurchaseOrderRefundInfoComponent,
  InputPurchaseOrderAddtionalInvoiceComponent,
  InputExpenseAccountManualInputComponent,
  InputExpenseAccountInvoiceInfoComponent,
  ExpenseAddComponent,
  InputExpenseAccountInvoiceEditComponent,
  InputTransferOutTransferComponent,
  InputStatisticalSignatureInvoiceStatisticsComponent,
  InputConfigurationTypeEditComponent,
  InputTransferOutConfigurationAddComponent,
  InputPurchaseOrderThreeSingleMatchesMatchResultComponent,
  InputInvoiceOverviewMatchComponent,
  InputExpenseAccountInvoiceViewComponent,
  InputInvoiceOverviewEditComponent,
  InvoiceInvoiceOverviewEditTicketComponent,
  InputConfigurationFaultTolerantEditComponent];

@NgModule({
  imports: [
    SharedModule,
    InputRoutingModule
  ],
  declarations: [
    ...COMPONENTS,
    ...COMPONENTS_NOROUNT
  ],
  exports: [InputTransferOutConfigurationAddComponent],
  entryComponents: COMPONENTS_NOROUNT
})
export class InputModule { }
